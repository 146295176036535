import React from 'react';
import './style.css';
import 'tailwindcss/dist/base.css';
import tw from 'twin.macro';
import styled from 'styled-components';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import AnimationRevealPage from '../helpers/AnimationRevealPage';
import Header from '../components/Layout/Header';
import Footer from '../components/Layout/Footer';
import { SectionHeading } from '../components/Misc/Headings';
import { Container, ContentWithPaddingXl } from '../components/Misc/Layouts';
import illustration from '../images/undraw_page_not_found_su7k.svg';
import Helmet from '../components/Layout/Helmet';

const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900 mb-10 text-left`;
const Text = styled.div`
  ${tw`text-lg  text-gray-800`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;

const ColumnContainer = tw.div`max-w-xl`;
const ImageContainer = tw(ColumnContainer)`m-auto mb-24`;

const Wrapper = styled.div`
  position: relative;
  padding: 2rem 2rem 0 2rem;
`;

export default function AboutPage() {
    const { t } = useTranslation();
    return (
        <>
            <Helmet title={t('helmet.home.title')} desc={t('helmet.home.description')} meta={t('helmet.home.meta')} />
            <AnimationRevealPage disabled>
                <Wrapper>
                    <Header />
                    <Container>
                        <ContentWithPaddingXl>
                            <HeadingRow>
                                <Heading>{t('404.heading')}</Heading>
                            </HeadingRow>
                            <Text>
                                <h1>
                                    {t('404.subheading')}
                                </h1>
                            </Text>
                        </ContentWithPaddingXl>
                        <ImageContainer>
                            <img src={illustration} alt="" />
                        </ImageContainer>
                    </Container>
                </Wrapper>
                <Footer />
            </AnimationRevealPage>
        </>
    );
}
